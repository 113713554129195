import React,{useRef} from 'react';
import Slider from 'react-slick';
import cn from 'classnames';
import AnimatedContainer from 'components/AnimatedContainer';
import TrustPilotIcon from 'components/IconModule/icons/trustpilot';
import TrustpilotCard from './TrustpilotCard';
import { TrustpilotList, GoogleReviewCarouselConfig } from '../../constants/trustpilot';
import css from './Trustpilot.module.scss';
import './press-slider.scss';
import { useTranslation } from 'react-i18next';
import ukarrowReview from 'static/img/home/pressSlider/ukarrowReview.svg'

export default function Trustpilot() {
  const { t } = useTranslation()
  const trustpilotRef = useRef<Slider>(null)
  const preArrowClick=()=>{
    trustpilotRef.current&&trustpilotRef.current.slickPrev()
  }
  const nextArrowClick=()=>{
    trustpilotRef.current&&trustpilotRef.current.slickNext()
  }
  return (
    <div className={css.wrapper}>
      <div className={css.titleBlock}>
        <TrustPilotIcon />
        <div className={css.title}>{t('View all of our Trustpilot Reviews')} </div>
      </div>
      <div className={css.sliderWrapper}>
        <Slider
          {...GoogleReviewCarouselConfig}
          ref={trustpilotRef}
          appendDots={dots=>{
            return(
              <div>
                <img onClick={preArrowClick} className={css.preArrow} src={ukarrowReview}/>
                {dots}
                <img onClick={nextArrowClick} className={css.nextArrow} src={ukarrowReview}/>
              </div>
            )
          }}
          className={cn(css.slider, "Trustpress-slider")}
        >
          {TrustpilotList.map((item, i) => (
            <AnimatedContainer key={i}>
              <TrustpilotCard {...item}/>
            </AnimatedContainer>
          ))}
        </Slider>
      </div>
    </div>
  )
}