import React, { useContext } from 'react';
import { AUTHENTICATED_ROUTES, KEY_CONTENT_ROUTES } from 'routing';
import { Redirect } from 'react-router-dom';
import UkVideoExceptional from './components/Video/UkVideoExceptional';
import PressSlider from './components/LogoPressSlider';
import Benefits from './components/Benefits/UkBenefits';
import UaeBenefits from './components/Benefits/UaeBenefits';
import FAQ from './components/FAQ/UkFAQ';
import GradeImprovementBlock from './components/GradeImprovementBlock';
import UaeGradeImprovementBlock from './components/GradeImprovementBlock/UaeGradeImprovementBlock';
import TopTutorsSubjects from './components/TopTutorsSubjects/UkTopTutorsSubjects';
import UaeTopTutorsSubjects from './components/TopTutorsSubjects/UaeTopTutorsSubjects';
import Banner from './components/Banner';
import Hero from './components/Hero';
import UaeHero from './components/Hero/UaeHero';
import AuthContext from 'context/AuthContext';
import OnlineLessonsBlock from './components/OnlineLessonsBlock/UkOnlineLessonsBlock';
import Trustpilot from './components/Trustpilot'
import HowItWorks from './components/HowItWorks/UkHowItWorks';
import UaeHowItWorks from './components/HowItWorks/UaeHowItWorks';
import GoogleReview from './components/GoogleReview';
import HelloLearn from './components/HelloLearn'
import Explore from './components/Explore';
import Find from './components/Find';
import Articles from './components/Articles'; 
import JoinOurTeam from './components/JoinOurTeam';
import ClassesBySubject from './components/ClassesBySubject'
import TermsWonder from './components/TermsWonder'
import css from './Home.module.scss';
import { isUAE } from 'utils/env';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { position } from 'styled-system';
import { History } from 'history';
import useWindowSize from 'utils/useWindowSize';
import { useTranslation } from 'react-i18next';
import { saveCountry, saveLevel, saveSubject } from 'utils/useSessionStorage';
interface LoginProps {
  history: History;
}
export default function Home({ history }: LoginProps) {
  const auth = useContext(AuthContext);
   
  const { t } = useTranslation();

  const { SCREEN_WIDTH } = useWindowSize();
  const isMobile = SCREEN_WIDTH < 768;

  // if (auth.user && auth.user.is_tutor) {
  //   return <Redirect to={AUTHENTICATED_ROUTES.TUTOR_DASHBOARD} />;
  // }

  return (
    <div className={isUAE?css.uaeHomeContainer:css.homeContainer}>
      {!isUAE&&<>
        <HelloLearn />
        {/* <Explore history={history}/> */}
        {!isMobile && <Find history={history} />}
        {isMobile && <a
            onClick={() => { saveSubject("0"); saveLevel("0"); saveCountry("0");}}
            href={KEY_CONTENT_ROUTES.SEARCH_RESULTS}
            className={css.findTutor}
          >
            <span>{t('Find Tutors')}</span>
        </a>}
        {/* <TermsWonder/> */}
        <UkVideoExceptional />
        <PressSlider />
        <ClassesBySubject history={history}/>
        <HowItWorks />
        {/* <JoinOurTeam /> */}
        <TopTutorsSubjects />
        {/* <Articles/> */}
        <Benefits />
        <OnlineLessonsBlock />
        <Trustpilot/>
        <GoogleReview />
        <FAQ />
        {/* <Banner />
        <Hero />
        <HowItWorks />
        <TopTutorsSubjects />
        <Benefits />
        <OnlineLessonsBlock />
        <FAQ />
        <GoogleReview />
        <Video />
        <GradeImprovementBlock /> */}
      </>}
      {isUAE&&<>
        {/* <UaeHero />
        <div className={css.uaeHowitworkTopTutorsSubjectsWarp}>
          <UaeHowItWorks />
          <UaeTopTutorsSubjects />
        </div>
        <UaeBenefits />
        <UaeGradeImprovementBlock /> */}
        <UaeHero />
        <UaeHowItWorks />
        <UaeGradeImprovementBlock />
      </>}
    </div>
  );
}
