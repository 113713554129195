import React, { 
    useState,
    useEffect,
    useContext,
    useReducer,
    createContext,
    useRef,
    ReactElement } from 'react';
import cn from 'classnames';
import {Col, Row, Icon} from 'antd';
import { Redirect ,Link} from 'react-router-dom';
import css from './HelloLearn.module.scss';
import ukHomeCommon from 'styles/common.module.scss'
import { useTranslation } from 'react-i18next';
import helloRgihtImg from 'static/img/helloRight.svg';
import circleImg from 'static/img/helloRightBackground.png';
import CompletedBlack from 'components/IconModule/icons/completedBlack';
import GoogleIcon from 'components/IconModule/icons/googleHello';
import StarRating from 'components/StarRating';
import LazyLoad from 'react-lazyload';
import queryString from 'query-string';
import trustpilotImg from 'static/img/home/hero/trustpilotImg2.png';
import googleLogo from 'static/img/googleView.svg'
import {
    KEY_CONTENT_ROUTES,
  } from 'routing';
import useWindowSize from 'utils/useWindowSize';

export default function Explore() {
   
    const { t } = useTranslation();

    const { SCREEN_WIDTH } = useWindowSize();
    const isMobile = SCREEN_WIDTH < 768;

    const openWhatsApp = () => {
        const phoneNumber = '1234567890';
        const message = 'Hello, I want to chat!';
        
        const url = `https://wa.me/${phoneNumber}?${queryString.stringify({ text: message })}`;
        window.open(url, '_blank');
    };

  return (
    <div>
        <div className={cn(css.helloLearnContainer)}>
            {/* {isMobile && <div className={css.helloWhatsappWrapper}>
                <a className={css.helloWhatsappButton} onClick={openWhatsApp} rel="noopener noreferrer">
                    <Icon type='phone'/><span>{t('TALK NOW ON WHATSAPP')}</span>
                </a>
            </div>} */}
            <Row className={css.helloRightWarp}>
                <Col lg={{span:10,offset:14}}  className={css.helloRight}>
                    <Row className={css.itemWarp}>
                        <Col 
                        xxl={{span:15}}
                        xl={{span:18}}
                        lg={{span:17}}
                        md={{span:14}}
                        sm={{span:18}}
                        xs={{span:20}}
                        >
                            <img className={css.circle} src={circleImg} alt="" />
                        </Col>
                    </Row>
                    <Row className={css.itemWarp}>
                        <Col 
                        xxl={{span:15}}
                        xl={{span:18}}
                        lg={{span:17}}
                        md={{span:14}}
                        sm={{span:18}}
                        xs={{span:20}}
                        className={css.pepoleWarp}
                        >
                            <img className={css.people} src={helloRgihtImg} alt="" />
                            {/* <div className={cn(css.tipWarp,css.free)}>
                                <span className={cn(css.freeCoursesImg,css.tipLeftImg)}></span>
                                <div className={css.tipRight}>
                                    <div className={css.freeCount}>3,000+</div>
                                    <div className={css.tipRightText}>{t('Free Courses')}</div>
                                </div>
                            </div>
                            <div className={cn(css.tipWarp,css.tutorForYou)}>
                                <span className={cn(css.tutorsImg,css.tipLeftImg)}></span>
                                <div className={css.tipRight}>
                                    <div className={css.tutorForYouCount}>3,000+</div>
                                    <div className={css.tipRightText}>{t('Tutors for you')}</div>
                                </div>
                            </div>
                            <div className={cn(css.tipWarp,css.topRate)}>
                                <span className={cn(css.topRatesImg,css.tipLeftImg)}></span>
                                <div className={css.tipRight}>
                                    <div className={css.topRateCount}>{t('Top Rated')}</div>
                                    <div className={css.tipRightText}>
                                        {t('Tutors')} 
                                        <Icon 
                                            style={{ color: '#FFA704',marginLeft:'12px',marginRight:'8px'} }
                                            type="star"
                                            theme="filled"
                                            />
                                        <span>8.9</span>
                                    </div>
                                </div>
                            </div> */}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col lg={13} className={css.helloLeft}>
                    <div className={css.trustRate}>
                        {/* <div>
                        <LazyLoad>
                            <img src={trustpilotImg} alt="" />
                        </LazyLoad>
                        </div> */}
                        <div className={css.ratedLabel}>{t('Rated Excellent')}</div>
                        <div className={css.logo}>
                        <LazyLoad>
                            <img src={googleLogo} alt="" />
                        </LazyLoad>
                            {/* <GoogleIcon />
                            <StarRating rating={5} fontSize={1.5} /> */}
                        </div>
                    </div>
                    
                    <div className={css.helloText}>
                        <div><span className={css.yourText}>{t('Your')}</span>{t(' Path to')} </div>
                        <div className={css.successText}>{t('Success')}</div>
                        <div className={css.madesimple}>{t('Starts Here')}</div>
                    </div>
                    <div className={css.helloDescription}>
                        {t("Personalized online tutoring tailored to every student's unique learning journey")}
                    </div>
                    {/* <Row className={css.twoBtnRow} style={{marginTop:"32px"}} gutter={20}>
                        <Col >
                            <Link to={KEY_CONTENT_ROUTES.SEARCH_RESULTS} className={css.helloBtnsWarp}>
                                <div className={css.helloBtn}>
                                {t('Find a Tutor')}
                                </div>
                            </Link>
                        </Col>
                        <Col  className={css.helloBtnsWarp}>
                            <a href='https://tutorhouse.co.uk/homeschooling' target='_blank'  className={css.helloBtn}>Home Schooling</a>
                        </Col>
                    </Row> */}
                    {/* <Row className={css.twoBtnRow} gutter={20}>
                        <Col  className={css.helloBtnsWarp}>
                            <a href='https://tutorhouse.co.uk/homeschooling' target='_blank'  className={css.helloBtn}>Home Schooling</a>
                        </Col>
                        <Col  className={css.helloBtnsWarp}>
                            <a href='https://tutorhouse.co.uk/a/coding' target='_blank' className={css.helloBtn}>Coding & Tech</a>
                        </Col>
                        <Col  className={css.helloBtnsWarp}>
                            <a href='https://oktopi.ac-page.com/tie-dye-drama' target='_blank' className={css.helloBtn}>Wellbeing</a>
                        </Col>
                    </Row>
                   
                    <div className={css.features}>
                        <span className={css.featureItem}><CompletedBlack /> {t('First Lesson FREE')}</span>
                        <span className={css.featureItem}><CompletedBlack /> {t('Trained & Qualified Tutors')} </span>
                        <span className={css.featureItem}><CompletedBlack /> {t('No Commitment')}</span>
                    </div>
                    <Link to={KEY_CONTENT_ROUTES.SEARCH_RESULTS} className={css.helloBtnsWarp}>
                        <div className={cn(css.helloBtn,css.findatutor)}>
                        {t('Find a Tutor')}
                        </div>
                    </Link> */}
                </Col>
            </Row>
            {/* {!isMobile && <div className={css.helloWhatsappWrapper}>
                <a className={css.helloWhatsappButton} onClick={openWhatsApp} rel="noopener noreferrer">
                    <Icon type='phone'/><span>{t('TALK NOW ON WHATSAPP')}</span>
                </a>
            </div>} */}
        </div>
    </div>
   
  );
}
