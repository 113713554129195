import React from 'react';
import { ITrustpilotList } from 'pages/Reviews/constants';
import TrustPilotRatingIcon from 'components/IconModule/icons/trustpilotRating';
import css from './TrustpilotCard.module.scss';
import { useTranslation } from 'react-i18next';

export default function TrustpilotCard({
  img,
  name,
  rating,
  time,
  subtitle,
  description,
  link
}: ITrustpilotList) {
  const { t } = useTranslation()
  return (
    <div className={css.wrapper}>
      <div className={css.header}>
        <div className={css.img}>{img}</div>
        <div className={css.name}>{name}</div>
      </div>
      <div className={css.subtitle}>
        <div className={css.rating}><TrustPilotRatingIcon /><span>{time}</span></div>
        <div className={css.subtitleText}>{subtitle}</div>
      </div>
      <div className={css.description}>
        {description}
        <a href={link} target="_blank">{t('More')}</a>
      </div>
    </div>
  )
}
