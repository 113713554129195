import React from 'react';
import IconContainer from '../IconContainer';

const svg = (
  <svg width="132" height="24" viewBox="0 0 132 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="24" height="24" fill="#00B67A"/>
<path d="M20.0625 9.62537L13.902 9.62537L12.0056 3.75L10.098 9.62537L3.9375 9.61411L8.91975 13.2496L7.01213 19.125L11.9944 15.4895L16.9766 19.125L15.0802 13.2496L20.0625 9.62537Z" fill="white"/>
<path d="M15.5625 14.4592L15.1286 13.125L12 15.375L15.5625 14.4592Z" fill="#00B67A"/>
<rect width="24" height="24" transform="translate(27)" fill="#00B67A"/>
<path d="M47.0625 9.62537L40.902 9.62537L39.0056 3.75L37.098 9.62537L30.9375 9.61411L35.9198 13.2496L34.0121 19.125L38.9944 15.4895L43.9766 19.125L42.0802 13.2496L47.0625 9.62537Z" fill="white"/>
<path d="M42.5625 14.4592L42.1286 13.125L39 15.375L42.5625 14.4592Z" fill="#00B67A"/>
<rect width="24" height="24" transform="translate(54)" fill="#00B67A"/>
<path d="M74.0625 9.62537L67.902 9.62537L66.0056 3.75L64.098 9.62537L57.9375 9.61411L62.9198 13.2496L61.0121 19.125L65.9944 15.4895L70.9766 19.125L69.0802 13.2496L74.0625 9.62537Z" fill="white"/>
<path d="M69.5625 14.4592L69.1286 13.125L66 15.375L69.5625 14.4592Z" fill="#00B67A"/>
<rect width="24" height="24" transform="translate(81)" fill="#00B67A"/>
<path d="M101.063 9.62537L94.902 9.62537L93.0056 3.75L91.098 9.62537L84.9375 9.61411L89.9198 13.2496L88.0121 19.125L92.9944 15.4895L97.9766 19.125L96.0802 13.2496L101.063 9.62537Z" fill="white"/>
<path d="M96.5625 14.4592L96.1286 13.125L93 15.375L96.5625 14.4592Z" fill="#00B67A"/>
<rect width="24" height="24" transform="translate(108)" fill="#00B67A"/>
<path d="M128.063 9.62537L121.902 9.62537L120.006 3.75L118.098 9.62537L111.937 9.61411L116.92 13.2496L115.012 19.125L119.994 15.4895L124.977 19.125L123.08 13.2496L128.063 9.62537Z" fill="white"/>
<path d="M123.563 14.4592L123.129 13.125L120 15.375L123.563 14.4592Z" fill="#00B67A"/>
</svg>
);

export default IconContainer(svg);