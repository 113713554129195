import React, { ReactNode } from 'react';

interface IIconProps {
  className?: string;
  color?: string;
}

export default function IconContainer (svg: ReactNode) {
  return function ({ className, color }: IIconProps) {
    return (
      <div className={className} style={{ color }}>
        {svg}
      </div>
    );
  }
}
