

export interface IGoogleReview {
  img: string;
  name: string;
  rating: number;
  time: string;
  positive: string;
  description: string;
  link: string;
}

export interface ITrustpilotList {
  img: string;
  name: string;
  rating: number;
  time: string;
  subtitle: string;
  description: string;
  link: string;
}

export const GoogleReviewCarouselConfig = {
  dots: true,
  slidesToShow: 3.5,
  slidesToScroll: 1,
  infinite: false,
  speed: 500,
  // arrows: true,
  responsive: [
    {
      breakpoint: 1660,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1050,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export const TrustpilotList: ITrustpilotList[] = [
  {
    img: 'Y',
    name: 'Yvonne K',
    rating: 5,
    time: '4 month ago',
    subtitle: 'Experience as a parent of a child tutored/assisted by Tutor House',
    description: `Tutor House has worked with my daughter over the past three years now; supporting her with one-to-one tuition for her A-Level French as well as assisting her throughout her UCAS process. Their friendly team has been extremely helpful (often responding...`,
    link: 'https://uk.trustpilot.com/users/5ee770c47fb435652fa81e40',
  },
  {
    img: 'E',
    name: 'Edward',
    rating: 5,
    time: '5 month ago',
    subtitle: 'We needed a tutor to help our son get an A in English Literature A Level',
    description: 'We needed a tutor to help our son get at least an A in his English Literature A level to get what he needed for University. Tutor House were extremely helpful and helped with choice of exam board, and enrollment in a suitable exam centre for an independent...',
    link: 'https://uk.trustpilot.com/users/4edb9c2c00006400010f8057'
  },
  {
    img: 'L',
    name: 'Lauren Lindsey',
    rating: 5,
    time: '4 month ago',
    subtitle: 'Exactly what you want from a tutor…',
    description: 'Exactly what you want from a tutor service, reliable, friendly and quality education.',
    link: 'https://uk.trustpilot.com/users/5895b09f0000ff000a73c4b1'
  },
  {
    img: 'D',
    name: 'Dee',
    rating: 5,
    time: '4 month ago',
    subtitle: 'Excellent',
    description: 'Have been using Tutor House for my daughter whom is a year 6 student and found the service to be excellent. She has really enjoyed the English and Maths work set out for her to complete online with her tutor and has come away more confident each time. As a...',
    link: 'https://uk.trustpilot.com/users/5ed181ccafcf38493152e2bb'
  },
  {
    img: 'J',
    name: 'Jamie Wickham-Eade',
    rating: 5,
    time: '4 month ago',
    subtitle: 'Absolutely amazing tutor finding…',
    description: 'Absolutely amazing tutor finding service. Would highly recommend to anyone in need of quality tutors!',
    link: 'https://uk.trustpilot.com/users/5fad033cbdde17001afc8386'
  },
];
